<template>
    
    <footer>
        <p>Powered by Tribus - Agência Digital </p>
    </footer>



</template>

<script>

export default({
    setup() {
        
    },

})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap');


footer{
    width: 100%;
    background-color: white;
    font-size: 13px;
    color: #078c26;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 100;
}

p{
    padding: 40px;
    margin: 0;
}



</style>
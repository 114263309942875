<template>
    
    <div class="contato" id="contato">

        <div class="row">
            <div class="col-6 item">
                <img  v-bind:src="require('../assets/icones/call.png')" alt="">
                <h3>NOSSO TELEFONE</h3>
                <p>Entre em contato conosco +55 16 3382-7192 | +55 16 99781-7818</p>
            </div>
            <div class="col-6 item">
                <img  v-bind:src="require('../assets/icones/letter.png')" alt="">
                <h3>CONTATO ONLINE</h3>
                <p>Entre em contato conosco vendas01@agromatao.com.br</p>
            </div>
            
        </div>


    </div>



</template>

<script>

export default({
    setup() {
        
    },
})
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;500&display=swap');

.contato{
    background-color: #138732;
    padding: 45px;
}

h3{
    color: var(--text-color);
    font-weight: 500;
    font-family: 'Noto Sans JP', sans-serif;
    padding: 0px;
    margin: 0px;
}
p{
    color: var(--text-color);
    font-weight: 100;
    font-family: 'Noto Sans JP', sans-serif;
    padding: 0px;
    margin: 0px;
}

img{
    width: 60px;
}

.item{
    padding: 8px;
}

</style>
